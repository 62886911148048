import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faAngleLeft,
  faAngleRight
} from '@fortawesome/pro-light-svg-icons'
import { connect } from 'react-redux'
import {addMetaData} from './../../src/actions/metaDataAction'
import { get } from './../../src/fetchController'
import ItemCustomInfo from '../ItemCustomInfo'


class SlideBlock extends Component {
  state = {
    slideNumber: 0
  }

  doPrevSlide = () => {
    if (this.state.slideNumber < 1) {
      this.setState({
        slideNumber: 2
      })
    } else {
      this.setState({
        slideNumber: this.state.slideNumber - 1
      })
    }
  }

  doNextSlide = () => {
    if (this.state.slideNumber > 1) {
      this.setState({
        slideNumber: 0
      })
    } else {
      this.setState({
        slideNumber: this.state.slideNumber + 1
      })
    }
  }


  componentDidMount(){
    let ids = this.props.fields.items.map((item) => {
      return item.item_item_px_id;
    });

    let filteredIds = ids.filter((id) => !this.props.metadata[id]);
    this.getMetaData(filteredIds);
  }

  getMetaDataById = (list, descId) => {
    let obj = list ? list.filter(data => data.descriptionId === descId) : null;
    return obj && obj[0] ? obj[0].metaInhalt : null;
  }


  getMetaData(ids){
    let formattedQuery = ids.reduce((prev, curr) => {
      if(prev === ""){
        return curr;
      }

      return prev + "," + curr
    }, "");
    
    get('/wp-json/amfrest/v1/metadata?contentId=' + formattedQuery).then(res => {
        this.props.addMetaData(res);
    })
  }

  render() {
    const { slideNumber } = this.state
    return (
      <section className={`sliding-block slide-position-${slideNumber}`}>
        <Container>
          <Row>
            <Col md="12">
              <div className="sectionTitle">
                <h4>{this.props.fields.title}</h4>
                <a href={"/sok?query=" + this.props.fields.search_string} className="arrow-link">
                  Visa alla
                  <FontAwesomeIcon icon={faArrowRight} />
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="slider__arrow left" onClick={this.doPrevSlide}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
            <div className="slider__arrow right" onClick={this.doNextSlide}>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div
              className="sliding-block__slider"
              style={{ transform: `translateX(${slideNumber * -100}%)` }}
            >
              {this.props.fields.items.map((field, index) => {
                return (
                  <Col
                    md={this.props.fields.block_size}
                    key={index}
                  >
                    <ItemCustomInfo acfField={field}  />
                  </Col>
                )
              })}
            </div>
          </Row>
        </Container>
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metadata: state.metaDataReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  addMetaData: (data) => {
    dispatch(addMetaData(data));
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlideBlock)
