import React, { Component } from 'react'
import Link from 'next/link'
import { Container, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { Config } from '../config.js'
import { get } from './../src/fetchController'
import {
  updateFooterOne,
  updateFooterThree,
  updateFooterTwo
} from './../src/actions/menuAction'
let clearMenuItems = function(menus) {
  return menus.map(menu => {
    menu.items = menu.items.map(item => {
      item.url = item.url.replace(Config.wpUrl, '')
      return item
    })
    return menu
  })
}

class Footer extends Component {
  componentDidMount() {
    let footer = [
      get('/wp-json/menus/v1/menus/footer-menu-1'),
      get('/wp-json/menus/v1/menus/footer-menu-2'),
      get('/wp-json/menus/v1/menus/footer-menu-3')
    ]
    let self = this
    Promise.all(footer).then(res => {
      res = clearMenuItems(res)
      self.props.updateFooterOne(res[0])
      self.props.updateFooterTwo(res[1])
      self.props.updateFooterThree(res[2])
    })
  }

  render() {
    const firstMenu = this.props.menu.footer_1
    const secondMenu = this.props.menu.footer_2
    const thirdMenu = this.props.menu.footer_3

    return (
      <footer>
        <Container>
          <Row>
            <Col key={100} md="3">
              <div className="footerColumn">
                <img src="/static/images/fba_logo.svg" />
                <p>{this.props.options.footer_text}</p>
              </div>
            </Col>
            <Col key={101} md="3">
              {firstMenu ? (
                <div className="footerColumn">
                  <h3>{firstMenu.name}</h3>
                  <ul>
                    {firstMenu.items.map((link,index) => {
                      return (
                        <li key={index}>
                          <Link href={link.url}>
                            <a>{link.title}</a>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ) : null}
            </Col>
            <Col key={102} md="3">
              {secondMenu ? (
                <div className="footerColumn">
                  <h3>{secondMenu.name}</h3>
                  <ul>
                    {secondMenu.items.map((link,index) => {
                      return (
                        <li key={index}>
                          <Link href={link.url} >
                            <a>{link.title}</a>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ) : null}
            </Col>
            <Col key={103} md="3">
              {thirdMenu ? (
                <div className="footerColumn">
                  <h3>{thirdMenu.name}</h3>
                  <ul>
                    {thirdMenu.items.map((link,index) => {
                      return (
                        <li key={index}>
                          <Link href={link.url}>
                            <a>{link.title}</a>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    menu: state.menuReducer,
    options: state.themeOptionReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateFooterOne: menu => {
    dispatch(updateFooterOne(menu))
  },
  updateFooterTwo: menu => {
    dispatch(updateFooterTwo(menu))
  },
  updateFooterThree: menu => {
    dispatch(updateFooterThree(menu))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
