import Router from 'next/router'

export function push(url, as) {
  Router.push(url, as).then(() => window.scrollTo(0, 0))
}

function replaceAll(str, find, replace) {
  if (str) {
    return str.replace(new RegExp(find, 'g'), replace)
  }
}

export function slugify(text) {
  text = replaceAll(text, 'å', 'a')
  text = replaceAll(text, 'ä', 'a')
  text = replaceAll(text, 'ö', 'o')
  if (text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }
}

export function getUrlFromPath(path) {
  let splitted = path.split('/').filter(part => part !== '')
  switch (splitted[0]) {
    case '/':
      return '/index'
    case 'kollektion':
      return '/collections'
    case 'redaktionellt':
      if (splitted.length > 1) {
        return '/editorial'
      }
      return '/editorials'
    case 'sok':
      return '/search'
    case 'post':
      return '/item'
    case 'sparade':
      return '/bookmarks'
    default:
      return '/post'
  }
}
