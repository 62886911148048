import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { connect } from 'react-redux'
import {addMetaData} from './../../src/actions/metaDataAction'
import { get } from './../../src/fetchController'
import ItemCustomInfo from '../ItemCustomInfo.js'
import Link from 'next/link'

export class ItemsBlock extends Component {

  componentDidMount(){
    let ids = this.props.fields.items.map((item) => {
      return item.item_item_px_id;
    });

    let filteredIds = ids.filter((id) => !this.props.metadata[id]);
    this.getMetaData(filteredIds);
  }

  getMetaData(ids){
    let formattedQuery = ids.reduce((prev, curr) => {
      if(prev === ""){
        return curr;
      }

      return prev + "," + curr
    }, "");
    
    get('/wp-json/amfrest/v1/metadata?contentId=' + formattedQuery).then(res => {
        this.props.addMetaData(res);
    })
  }


  render() {
    return (
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="sectionTitle">
                <h4>{this.props.fields.title}</h4>
                {this.props.search_string ? <Link href={"/sok?query=" + this.props.fields.search_string} >
                  <a className="arrow-link">
                    Visa alla
                    <FontAwesomeIcon icon={faArrowRight} />
                  </a>
                </Link> : ''}

              </div>
            </Col>
          </Row>
          <Row>
            {this.props.fields.items.map((field, index) => {
              return (
                <Col
                  md={this.props.fields.block_size}
                  key={index}
                >
                  <ItemCustomInfo acfField={field} isItem={true} />
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metadata: state.metaDataReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  addMetaData: (data) => {
    dispatch(addMetaData(data));
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemsBlock)
