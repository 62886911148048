import React, { Component } from 'react'
import { get } from '../src/fetchController'

class Collection extends Component {
  state = {
    image: ''
  }

  componentDidMount() {
    const block = get(`/wp-json/acf/v3/collections/${this.props.id}`)

    block.then(
      value =>
        this.setState({
          image: value.acf.collection_image
        }),
      error => console.log(error.message)
    )
  }

  render() {
    const { image } = this.state

    return (
      <a href={`/kollektion/${this.props.slug}`} className="content-block">
        <div
          className="content-block__thumbnail"
          style={{ backgroundImage: `url(${image}})` }}
        >
          <div className="darkCover" />
          <div className="content-block__thumbnail-content">
            <h5>Kollektion</h5>
            <h3>{this.props.title}</h3>
          </div>
        </div>
      </a>
    )
  }
}

export default Collection
