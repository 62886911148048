import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/pro-light-svg-icons'
import { faBookmark as faBookmarked } from '@fortawesome/free-solid-svg-icons'
import { push, slugify } from './../src/navigation'
import { get } from './../src/fetchController'
import { connect } from 'react-redux'
import Link from 'next/link'
import {TOKEN} from '../authentication/config'
import { addMetaData } from './../src/actions/metaDataAction'

export const getFromMetaData = (metadata, key) => {
  if (!metadata) {
    return ""
  }
  

  let filter = metadata.filter(data => data.descriptionId === key)[0]
  if(!filter || !filter.metaInhalt){
    return ""
  }
  return filter && filter.metaInhalt !== "null" ? filter.metaInhalt : ""
}

class Item extends Component {
  state = {
    //bookmarked: false,
    title: '',
    time: '',
    text: ''
  }


  isOk(item){
    return item && item !== ""
  }

  componentDidMount(){
    let id = this.props.id
    if (!this.props.metadata[id]) {
      get('/wp-json/amfrest/v1/metadata?contentId=' + id).then(res => {
        this.props.addMetaData(res)
      })
    }
  }

  render() {
    console.log(list)
    let { bookmarked } = this.state
    let list = this.props.metadata[this.props.id]
    let title = getFromMetaData(list, 'amfArkiv') + " " + getFromMetaData(list, 'amfBataljon_avdelning')
    let time = getFromMetaData(list, 'amfTidfran') + " - " + getFromMetaData(list, 'amfTidtill')
    let text = getFromMetaData(list, 'amfAllmananmarkning')
    text = text ? text.replace(/<\/?[^>]+(>|$)/g, '') : null
    text = text ? text.substring(0, 100) + '...' : null

    let bgImage = this.props.specialImage ? 'url("' + this.props.specialImage + '")' : 'url("' +
      'https://stream.amfarkiv.se/proxsys-json/stream/content/thumbnail?auth=' + TOKEN + '&contentId=' +
      this.props.id +
      '&width=320&height=200' +
      '")'

    return (
      <div className="content-block">
        <Link href={ this.props.image ? '/folder/' + this.props.id + '/' + slugify(this.isOk(this.props.title) ? this.props.title : title ) : '/post/' + this.props.id + '/' + slugify(this.isOk(this.props.title) ? this.props.title : title ) } >
          <a>
            <div
              className="content-block__thumbnail"
              style={{ backgroundImage: bgImage }}
              key={1}
            >
              <div className="darkCover" />
              <div className="timestamp">
                {this.isOk(this.props.time) ? this.props.time :  time}
              </div>
            </div>
            <div className="content-block__info" key={2}>
              <h3>{this.isOk( this.props.title) ?  this.props.title : title}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.isOk(this.props.text) ? this.props.text : text 
                }}
              />
            </div>
          </a>
        </Link>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metadata: state.metaDataReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  addMetaData: data => {
    dispatch(addMetaData(data))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item)
