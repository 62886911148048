import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faBookmarked } from '@fortawesome/free-solid-svg-icons'
import { push, slugify } from './../src/navigation'
import Link from 'next/link'
import {TOKEN} from '../authentication/config'

class ItemCustomInfo extends Component {
  state = {
    bookmarked: false,
    id: '',
    description: {},
    descList: []
  }

  getMetaData = (list, descId) => {
    let obj = list ? list.filter(data => data.descriptionId === descId) : null
    return obj && obj[0] &&obj[0].metaInhalt !== "null" ? obj[0].metaInhalt : ""
  }

  componentDidMount() {}

  toggleBookmark = () => {
    this.setState({
      bookmarked: !this.state.bookmarked
    })
  }

  doDescription = description => {
    if (this.props.acfField.item_item_description) {
      return <p>{this.props.acfField.item_item_description}</p>
    } else {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: description.description
          }}
        />
      )
    }
  }

  doImage = description => {
    if (this.props.acfField.item_item_thumbnail) {
      return this.props.acfField.item_item_thumbnail
    } else {
      
      return `https://stream.amfarkiv.se/proxsys-json/stream/content/thumbnail?auth=${TOKEN}&contentId=${
        this.props.acfField.item_item_px_id
      }&width=320&height=200`
    }
  }

  render() {
    let { bookmarked } = this.state

    let metadata = this.props.metadata[this.props.acfField.item_item_px_id]
    
    let title = this.getMetaData(metadata, "amfArkiv") +" " +  (this.getMetaData(metadata, "amfBataljon_avdelning")  ? this.getMetaData(metadata, "amfBataljon_avdelning")  :"" )
    let duration = this.getMetaData(metadata, 'amfTidfran') ? this.getMetaData(metadata, 'amfTidfran') + " - " + this.getMetaData(metadata, 'amfTidtill') : null
    let descriptionText = this.getMetaData(
      metadata,
      "amfAllmananmarkning"
    )
    descriptionText = descriptionText
      ? descriptionText.replace(/<\/?[^>]+(>|$)/g, '')
      : null
    descriptionText = descriptionText
      ? descriptionText.substring(0, 100) + '...'
      : null
    let image = `https://stream.amfarkiv.se/proxsys-json/stream/content/thumbnail?auth=${TOKEN}&contentId=${
      this.props.acfField.item_item_px_id
    }&width=320&height=200`

    

    let description = {
      title: title,
      duration: duration,
      description: descriptionText,
      image: image
    }


    let href = this.props.acfField.item_image ? '/folder/' +
    this.props.acfField.item_item_px_id +
    '/' +
    slugify(
      this.props.acfField.item_item_title
        ? this.props.acfField.item_item_title
          ? this.props.acfField.item_item_title
          : ''
        : description.title
        ? description.title
        : ''
    ) : 
    '/post/' +
    this.props.acfField.item_item_px_id +
    '/' +
    slugify(
      this.props.acfField.item_item_title
        ? this.props.acfField.item_item_title
          ? this.props.acfField.item_item_title
          : ''
        : description.title
        ? description.title
        : ''
    )
    return (
      <div className="content-block">
        <Link
          href={
            href
          }
        >
          <a>
            <div
              className="content-block__thumbnail"
              style={{
                backgroundImage: `url(${this.doImage(description)})`
              }}
            >
              <div className="darkCover" />
              {/* <div className="bookmark">
              <FontAwesomeIcon
                icon={bookmarked ? faBookmarked : faBookmark}
                onClick={this.toggleBookmark}
              />
            </div> */}
              <div className="timestamp">{description.duration}</div>
            </div>
            <div className="content-block__info">
              <h3>
                {this.props.acfField.item_item_title
                  ? this.props.acfField.item_item_title
                  : description.title}
              </h3>
              {this.doDescription(description)}
            </div>
          </a>
        </Link>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metadata: state.metaDataReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemCustomInfo)
