import { Config } from './../config'
require('tls').DEFAULT_ECDH_CURVE = 'auto'
import Cookie from 'js-cookie'

export async function post(method, body) {
  let idToken = Cookie.get("idToken")
  const pageRes = await fetch(Config.wpUrl + method, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization':idToken      
    },
    credentials: 'include',

    body: JSON.stringify(body)
  })

  return await pageRes.json()
}

// TODO: error handling
export async function get(url) {
  let idToken = Cookie.get("idToken")
  const pageRes = await fetch('https://adminfba.amfarkiv.se' + url, {
    method: 'GET',
    headers: {
      'Authorization':idToken
    }
  })
  
  try{
    const page = await pageRes.clone().json()
    return page
  } catch(e){
    return await pageRes.text()
  }
  
}
