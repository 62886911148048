import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from '../layouts/Layout.js'
import PageWrapper from '../components/PageWrapper.js'
import Menu from '../components/Menu.js'
import Flexible from '../components/Flexible'
import { Config } from '../config.js'
import { updateFlexible } from './../src/actions/flexibleAction'
import { get } from './../src/fetchController'
import { updateHeader } from './../src/actions/headerAction'
import SecurePage from './../hocs/securePage'
require('es6-promise').polyfill()
require('isomorphic-fetch')

let clearMenuItems = function(menus) {
  return menus.map(menu => {
    menu.items = menu.items.map(item => {
      item.url = item.url.replace(Config.wpUrl, '')
      return item
    })
    return menu
  })
}

class Index extends Component {
  static async getInitialProps(context) {
    // Get the data for the page
    const page = await get(`/wp-json/postlight/v1/page?slug=startpage`)

    context.store.dispatch(updateFlexible(page.acf))
    return { page, query: context.query }
  }

  componentDidMount() {
    this.props.updateHeader(true)
  }

  render() {
    console.log(this.props)
    
    return (
      <Layout hasHero>
        <Menu menu={this.props.headerMenu} />
        <Flexible />
      </Layout>
    )
  }
}

const mapStateToProps = (state, ownProps) =>
  function() {
    return {
      state
    }
  }

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateFlexible: flexible => {
    dispatch(updateFlexible(flexible))
  },
  updateHeader: hasHero => {
    dispatch(updateHeader(hasHero))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurePage(Index))
