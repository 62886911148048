import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faAngleLeft,
  faAngleRight
} from '@fortawesome/pro-light-svg-icons'
import { connect } from 'react-redux'
import {addMetaData} from './../src/actions/metaDataAction'
import { get } from './../src/fetchController'
import Item, { getFromMetaData } from '../components/Item.js'


class EditorialBlock extends Component {

  componentDidMount(){
   
    this.getMetaData([this.props.fields.item_px_id]);
  }

  getMetaDataById = (list, descId) => {
    let obj = list ? list.filter(data => data.descriptionId === descId) : null;
    return obj && obj[0] ? obj[0].metaInhalt : null;
  }


  getMetaData(ids){
    let formattedQuery = ids.reduce((prev, curr) => {
      if(prev === ""){
        return curr;
      }

      return prev + "," + curr
    }, "");
    
    get('/wp-json/amfrest/v1/metadata?contentId=' + formattedQuery).then(res => {
        this.props.addMetaData(res);
    })
  }

  render() {
    return (
        <Col md="12">
            <Item
            image={this.props.fields.image}
            title={this.props.fields.item_title}
            specialImage={this.props.fields.item_thumbnail}
            text={this.props.fields.item_description}
            id={this.props.fields.item_px_id}
            fetchMetaData={false}
            />
      </Col>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metadata: state.metaDataReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  addMetaData: (data) => {
    dispatch(addMetaData(data));
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorialBlock)