import React from 'react'
import Router from 'next/router'

import { getUserFromServerCookie, getUserFromLocalCookie } from '../authentication/auth'

const PAGE =  function(Page){ return (class DefaultPage extends React.Component {

  static async getInitialProps(args) {
    const loggedUser = process.browser ? getUserFromLocalCookie() : getUserFromServerCookie(args.req)
    const pageProps = Page.getInitialProps ? await Page.getInitialProps(args) : null
    return {
          ...pageProps,
          loggedUser,
          currentUrl: args.pathname,
          isAuthenticated: !!loggedUser
    }
  }

  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
  }

  logout(eve) {
    if (eve.key === 'logout') {
      Router.push(`/?logout=${eve.newValue}`)
    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.logout, false)
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.logout, false)
  }

  render() {
    return (
        <Page {...this.props} />
    )
  }
}
)}

export default PAGE;