import React, { Component } from 'react'
import Link from 'next/link'
import { Config } from '../config.js'
import { Nav, Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faSearch } from '@fortawesome/pro-light-svg-icons'
import { connect } from 'react-redux'
import { get } from './../src/fetchController'
import { updateMenus } from './../src/actions/menuAction'
import { updateThemeOptions } from './../src/actions/themeOptionsAction'
import { push, replaceAll, slugify, getUrlFromPath } from './../src/navigation'
import Router from 'next/router'

import HeaderSearchResults from '../components/HeaderSearchResults'

let clearMenuItems = function(menu) {
  menu.items = menu.items.map(item => {
    item.url = item.url.replace(Config.wpUrl, '')
    return item
  })
  return menu
}

class Menu extends Component {
  state = {
    isActive: false,
    isSearching: false,
    searchIndex: -1,
    results: [],
    isOpenMobileMenu: false,
    inputVal: ''
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)

    get('/wp-json/menus/v1/menus/header-menu').then(header => {
      this.props.updateMenus(clearMenuItems(header))
    })

    get('/wp-json/acf/v3/options/theme-settings').then(options => {
      this.props.updateThemeOptions(options.acf)
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (scrollTop > 50) {
      this.setState({
        isActive: true
      })
    } else {
      this.setState({
        isActive: false
      })
    }
  }

  getSlug = url => {
    const parts = url.split('/')
    return parts.length > 2 ? parts[parts.length - 2] : ''
  }

  handleChange = event => {
    this.setState({
      inputVal: event.target.value
    })

    if (event.target.value.length >= 1) {
      this.setState({
        isSearching: true
      })
      document.addEventListener('keydown', this.handleKeyPress, false)
    } else {
      this.setState({
        isSearching: false
      })
      document.removeEventListener('keydown', this.handleKeyPress, false)
    }
  }

  toggleMobileMenu = () => {
    this.setState({
      isOpenMobileMenu: !this.state.isOpenMobileMenu,
      isActive: true
    })
  }

  navigate(path) {
    push("/search", "/sok")
  }

  // handleKeyPress = event => {
  //   if (event.keyCode === 38) {
  //     if (this.state.searchIndex !== -1) {
  //       this.setState({
  //         searchIndex: this.state.searchIndex - 1
  //       })
  //     }
  //   } else if (event.keyCode === 40) {
  //     if (this.state.searchIndex !== 4) {
  //       this.setState({
  //         searchIndex: this.state.searchIndex + 1
  //       })
  //     }
  //   } else if (event.keyCode === 13) {
  //     
  //     this.navigate(
  //       `/sok?query=${this.state.inputVal}`
  //     )
  //   }
  // }

  handleKeyPress = event => {
    if (event.keyCode === 13) {
      window.location.href = "/sok?query=" + event.target.value
    }
  }

  render() {
    const {
      isActive,
      isSearching,
      searchIndex,
      results,
      isOpenMobileMenu
    } = this.state
    const menu = this.props.menu.header

    return (
      <header
        id="header"
        className={`${isActive  ? 'active' : ''} ${
          isOpenMobileMenu ? 'mobile-open' : ''
        }`} 
      >
        <div className="grid-container">
          <Link href="/">
            <a className="logoWrap">
              <div className="logo" />
            </a>
          </Link>
          <div className={`search-wrapper ${isSearching ? 'isSearching' : ''}`}>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className="form-control"
              placeholder="Sök..."
              onChange={this.handleChange}
              value={this.state.inputVal}
              spellCheck="false"
            />
            {/* <HeaderSearchResults searchIndex={searchIndex} results={results} /> */}
          </div>
          <Nav>
            {menu
              ? menu.items.map((link, index) => {
                  return (
                    <Link href={link.url} key={index}>
                      <a style={ Router.router && Router.router.pathname === "/itemDocument" ? {color:"#000"} : {}}>
                        {link.title}
                      </a>
                    </Link>
                  )
                })
              : null}
              <Link href={"/logout"}>
                <a style={Router.router && Router.router.pathname === "/itemDocument" ? {color:"#000"} : {}}>
                  Logga ut
                </a>
              </Link>
              
            {/* <div className="bookmarked-notice">
              <Link href="/sparade/lista">
                <a>
                  <FontAwesomeIcon icon={faBookmark} />
                  <span>0</span>
                </a>
              </Link>
            </div> */}
          </Nav>
          <div className="mobile-menu" onClick={this.toggleMobileMenu}>
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
        </div>
        <div className="mobile-navigation">
          <Container>
            <Row>
              <Col md="12">
                <Nav>
                  {menu
                    ? menu.items.map((link, index) => {
                        return (
                          <Link key={index} href={link.url}>
                            <a>
                              {link.title}
                            </a>
                          </Link>
                        )
                      })
                    : null}
                  {/* <div className="bookmarked-notice">
                    <Link href="/sparade/lista">
                      <a>
                        Sparade
                        <FontAwesomeIcon icon={faBookmark} />
                        <span>0</span>
                      </a>
                    </Link>
                  </div> */}
                  
                </Nav>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    menu: state.menuReducer,
    options: state.themeOptionReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMenus: header => {
    dispatch(updateMenus(header))
  },
  updateThemeOptions: menu => {
    dispatch(updateThemeOptions(menu))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu)
