import React, { Component } from 'react'
import Header from '../components/Header'
import { connect } from 'react-redux'
import { get } from './../src/fetchController'
import { updateThemeOptions } from './../src/actions/themeOptionsAction'
import { Config } from '../config.js'

class AuthLayout extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="auth-layout">
          <div className="auth-layout__image">
            <div className="auth-payoff">
              <h2>Folke Bernadotteakademin</h2>
              <h3>Myndigheten för fred, säkerhet och utveckling</h3>
            </div>
            <div className="auth-logo">
              <img src="/static/images/fba_logo.svg" alt="FBA logo" />
            </div>
          </div>
          <div className="auth-layout__form">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateThemeOptions: options => {
    dispatch(updateThemeOptions(options))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthLayout)
