import React, { Component } from 'react'
import Link from 'next/link'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class FaqBlock extends Component {
  render() {
    return (
      <section className="cat-block">
        <Container>
          <Row>
            <Col md="12">
              <div
                className={`callToAction-block ${
                  this.props.fields.background_color
                } `}
              >
                <div className="callToAction-block__info">
                  <h3>{this.props.fields.title}</h3>
                  <Link href={this.props.fields.link_url}>
                    <a>
                      {this.props.fields.link_text}
                      <FontAwesomeIcon icon={['fal', 'arrow-right']} />
                    </a>
                  </Link>
                </div>
                <div
                  className="callToAction-block__image"
                  style={{
                    backgroundImage: `url(${this.props.fields.image}})`
                  }}
                />
                <div className="slant" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default FaqBlock
