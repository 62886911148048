import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

import Collection from '../Collection.js'

class CollectionBlock extends Component {
  render() {
    return (
      <section className="collection-block">
        <Container>
          <Row>
            <Col md="12">
              <div className="sectionTitle">
                <h4>{this.props.fields.title}</h4>
                <div className="arrow-link" style={{display:"none"}}>
                  Visa alla
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {this.props.fields.collections.map((collection,index) => {
              return (
                <Col xs="6" md="3" key={index}>
                  <Collection
                    id={collection.collection.ID}
                    title={collection.collection.post_title}
                    slug={collection.collection.post_name}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
    )
  }
}

export default CollectionBlock
