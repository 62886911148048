import Layout from '../layouts/Layout'
import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import Menu from '../components/Menu.js'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Page extends Component {
  render() {
    return (
      <Layout>
        <Menu menu={this.props.headerMenu} />
        <Container className="pageLayout">
          <Row>
            <Col md="12">
              <div className="four-o-four">
                <h2>404</h2>
                <h3>
                  Innehållet du letar efter finns inte
                  <br /> eller har blivit borttaget.
                </h3>
                <Link href="/">
                  <Button color="primary">
                    Tillbaka till startsidan
                    <FontAwesomeIcon icon={['fal', 'arrow-right']} />
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}
