import React, { Component } from 'react'
import { Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { connect } from 'react-redux'
import { updateHero } from './../src/actions/heroAction'
import { addMetaData } from './../src/actions/metaDataAction'
import Slide from '../components/Slide.js'
import { get } from './../src/fetchController'

class Hero extends Component {
  state = {
    descList: [],
    titles: []
  }

  doPrevSlide = () => {
    if (this.props.hero.heroIndex - 1 < 0) {
      this.props.updateHero(2)
    } else {
      this.props.updateHero(this.props.hero.heroIndex - 1)
    }
  }

  doNextSlide = () => {
    if (this.props.hero.heroIndex + 1 > this.props.swipe.length - 1) {
      this.props.updateHero(0)
    } else {
      this.props.updateHero(this.props.hero.heroIndex + 1)
    }
  }

  setSlide = number => {
    this.props.updateHero(number)
  }

  getTitle(metadata) {
    let obj = metadata.filter(
      data => data.descriptionId === '4028808769b923650169c46aeb320005'
    )
    return obj[0].metaInhalt
  }

  getMetaData(ids) {
    let formattedQuery = ids.reduce((prev, curr) => {
      if (prev === '') {
        return curr
      }

      return prev + ',' + curr
    }, '')

    get('/wp-json/amfrest/v1/metadata?contentId=' + formattedQuery).then(
      res => {
        this.props.addMetaData(res)
      }
    )
  }

  componentDidMount() {
    let ids = this.props.swipe.map(swipe => swipe.slide_px_id)
    let filteredIds = ids.filter(id => !this.props.metadata[id])
    this.getMetaData(filteredIds)
  }

  render() {
    let slideNumber = this.props.hero.heroIndex
    return (
      <div className="hero contentTop">
        {this.props.swipe.map((slide, index) => (
          <Slide
            acfSlide={slide}
            isActive={this.props.hero.heroIndex == index}
            index={index}
            key={index}
          />
        ))}
        <div className="hero__arrow left">
          <FontAwesomeIcon onClick={this.doPrevSlide} icon={faAngleLeft} />
        </div>
        <div className="hero__arrow right">
          <FontAwesomeIcon onClick={this.doNextSlide} icon={faAngleRight} />
        </div>

        <div className="hero-bottom">
          <Container>
            <div className="hero-bottom__navigation">
              {this.props.swipe.map((slide, index) => (
                <div
                  key={index}
                  className={`item ${slideNumber == index ? 'active' : ''}`}
                  onClick={() => this.setSlide(index)}
                >
                  {' '}
                  {this.props.metadata[this.props.swipe[index].slide_px_id]
                    ? this.getTitle(
                        this.props.metadata[this.props.swipe[index].slide_px_id]
                      )
                    : slide.slide_title}
                </div>
              ))}
            </div>
            <div className="hero-bottom__pageCount">{slideNumber + 1}/3</div>
          </Container>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    flexible: state.flexibleReducer,
    hero: state.heroReducer,
    metadata: state.metaDataReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateHero: heroIndex => {
    dispatch(updateHero(heroIndex))
  },
  addMetaData: data => {
    dispatch(addMetaData(data))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hero)
