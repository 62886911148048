import React, { Component } from 'react'
import FaqItem from './FaqItem.js'

class FaqBlock extends Component {
  render() {
    return (
      <div className="faq-block">
        <div className="sectionTitle">{this.props.fields.title}</div>
        {this.props.fields.questions.map((question,index) => {
          return (
            <FaqItem question={question.question} answer={question.answer} key={index} />
          )
        })}
      </div>
    )
  }
}

export default FaqBlock
