import React from "react";
import { Config } from "../config.js";
import { connect } from 'react-redux'

const PageWrapper = function(Comp){ return(
  class pageWrapp extends React.Component {
    static async getInitialProps(args) {
      
      return {
        ...(Comp.getInitialProps ? await Comp.getInitialProps(args) : null),
      };
    }

    render() {
      return (
        <Comp {...this.props} />
      )
    }
  }
)}

export default PageWrapper;
