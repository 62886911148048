import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { get } from '../../src/fetchController'
import Link from 'next/link'

class EditorialBlock extends Component {
  state = {
    page: {},
    url: '',
    date: ''
  }

  async componentDidMount() {
    let page = await get('/wp-json/wp/v2/editorials/' + this.props.id)
    this.setState({ page, url: window.location.pathname })

    if (this.state.page) {
      let date = new Date(this.state.page.date)
      this.setState({ date: date })
    }
  }

  getMonth = month => {
    switch (month) {
      case 0:
        return 'Januari'
      case 1:
        return 'Februari'
      case 2:
        return 'Mars'
      case 3:
        return 'April'
      case 4:
        return 'Maj'
      case 5:
        return 'Juni'
      case 6:
        return 'Juli'
      case 7:
        return 'Augusti'
      case 8:
        return 'September'
      case 9:
        return 'Oktober'
      case 10:
        return 'November'
      case 11:
        return 'December'
      default:
        return 'December'
    }
  }

  render() {
    let { url, page, date } = this.state
    return (
      <section className="editorial-block">
        <Container>
          <Row>
            <Link
              href={`${
                url.split(/\/(?=.)/).length > 0 ? 'redaktionellt' : ''
              }/${page.slug}`}
            >
              <a className="content-block">
                <Col md="12" lg="8">
                  <div
                    className="content-block__thumbnail"
                    style={{
                      backgroundImage: `url(${
                        page.acf ? page.acf.editorial_thumbnail : null
                      })`
                    }}
                  />
                </Col>
                <Col md="12" lg="4">
                  <div className="content-block__info">
                    <h5>Redaktionellt</h5>
                    <h3>{page.title ? page.title.rendered : null}</h3>
                    <h5 className="editorial-date">
                      {date ? (
                        <div>
                          {date.getDate()} {this.getMonth(date.getMonth())},{' '}
                          {date.getFullYear()}
                        </div>
                      ) : null}
                    </h5>
                    <p>{page.acf ? page.acf.editorial_excerpt : null}</p>
                    <div className="arrow-link">
                      Läs mer
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </div>
                </Col>
              </a>
            </Link>
          </Row>
        </Container>
      </section>
    )
  }
}

export default EditorialBlock
