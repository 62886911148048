import React, { Component } from 'react'

class BlockEditor extends Component {
  render() {
    return (
      <section>
          <div className="container" dangerouslySetInnerHTML={{__html: this.props.fields.content}} />
      </section>
    )
  }
}

export default BlockEditor