import React, { Component } from 'react'
import { connect } from 'react-redux'
import Hero from './Hero'
import BlockItems from './content/ItemsBlock'
import EditorialBlock from './content/EditorialBlock'
import CollectionBlock from './content/CollectionBlock'
import SlideBlock from './content/SlideBlock'
import FaqBlock from '../components/FaqBlock.js'
import CallToAction from '../components/CallToAction.js'
import BlockEditor from './BlockEditor'
import { Container, Row, Col } from 'reactstrap'
import Item, { getFromMetaData } from '../components/Item.js'
import EditBlock from './EditorialBlock'
import ReportErrorBlock from "./content/ReportErrorBlock";

class Flexible extends Component {
  render() {
    return (
      <div style={{width:"100%"}}>
        {this.props.flexible.flexible
          ? this.props.flexible.flexible.map((field, key) => {
              switch (field.acf_fc_layout) {
                case 'hero':
                  return <Hero key={key} swipe={field.slides} />
                case 'block_items':
                  if (field.block_type === 'slide') {
                    return <SlideBlock key={key} fields={field} />
                  } else {
                    return <BlockItems key={key} fields={field} />
                  }
                case 'block_editorial':
                  return (
                    <EditorialBlock id={field.editorial_article.ID} key={key} />
                  )
                case "block_item":
                  return <EditBlock fields={field} key={key} />;
                case "block_red_item":
                  return <Col className={"block_red_item"} lg={{size: 8, offset: 2}}><EditBlock fields={field} key={key} /></Col>
                case 'block_editor':
                  return <BlockEditor key={key} fields={field} />
                case 'block_collections':
                  return <CollectionBlock key={key} fields={field} />
                case 'block_faq':
                  return <FaqBlock key={key} fields={field} />
                case 'block_call-to-action':
                  return <CallToAction key={key} fields={field} />
                case 'block_report_error':
                    return <ReportErrorBlock key={key} fields={field}/>
                case 'block_red_editor':
                  return <Col lg={{ size: 8, offset: 2 }}><BlockEditor key={key} fields={field}/></Col>
                default:
                  return <p>default</p>
              }
            })
          : null}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    flexible: state.flexibleReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Flexible)
