import React, { Component } from 'react'
import { push, replaceAll, slugify, getUrlFromPath } from './../src/navigation'

export class HeaderSearchResults extends Component {
  navigate = path => {
    let url = getUrlFromPath(path)
    push(url, path)
  }

  render() {
    return (
      <div className="search-results">
        <ul>
          {this.props.results.map((result, index) => (
            <li
              className={this.props.searchIndex === index ? 'active' : ''}
              key={index}
            >
              <a
                onClick={() => {
                  this.navigate(`/sok?${result.search_string}`)
                }}
              >
                {result.search_string}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default HeaderSearchResults
