import React, { Component } from 'react';
import {Container, Row, Col, Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCheck} from "@fortawesome/pro-light-svg-icons";
import {post} from './../../src/fetchController';


export default class ReportErrorBlock extends Component {

    state = {
        url: '',
        name: '',
        email: '',
        description: '',
        sent: false,
    }
    handleChange (evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    send() {
        post("/wp-json/amfrest/v1/handle_error", this.state).then((res) => {
          this.setState({sent: true, url: '', name: '', email: '', description: '',})
          
        })
    }

    render() {
        return (
            <section className={"reporterror"}>
                <h2 className="reporterror__title">Skicka in förbättringsförslag genom formuläret</h2>
                <div className="reporterror__formgroup">
                    <label htmlFor="form-url" className={"reporterror__label"}>Sida som förslaget gäller</label>
                    <input value={this.state.url} type="text" id="form-url" className={"reporterror__input"} name="url" onChange={(e) => this.handleChange(e)} />
                </div>
                <div className="reporterror__formgroup">
                    <label htmlFor="form-name" className={"reporterror__label"}>För- och efternamn</label>
                    <input value={this.state.name} type="text" id={"form-name"} className={"reporterror__input"} name={"name"} onChange={(e) => this.handleChange(e)} />
                </div>
                <div className="reporterror__formgroup">
                    <label htmlFor={"form-email"} className={"reporterror__label"}>E-postadress</label>
                    <input value={this.state.email} type="text" id={"form-email"} className={"reporterror__input"} name={"email"} onChange={(e) => this.handleChange(e)} />
                </div>
                <div className="reporterror__formgroup">
                    <label htmlFor={"form-description"} className={"reporterror__label"}>Beskriv ditt förbättringsförslag</label>
                    <textarea id={"form-description"} className={"reporterror__textarea"} name={"description"} onChange={(e) => this.handleChange(e)} value={this.state.description} />
                </div>

                <Button className="req-button" color="primary" onClick={() => this.send()}>
                    {this.state.sent ? 'Rapport skickad' : 'Skicka rapport om fel' }
                    {this.state.sent ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faArrowRight}/> }
                </Button>

            </section>
        )
    }
}