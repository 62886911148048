import Header from '../components/Header'
import Footer from '../components/Footer'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from './../src/fetchController'
import { updateMenu } from './../src/actions/menuAction'
import { updateHeader } from './../src/actions/headerAction'
import { updateThemeOptions } from './../src/actions/themeOptionsAction'
import { Config } from '../config.js'

class Layout extends Component {
  render() {
    const {
      props: { hasHero }
    } = this
    return (
      <div
        className={
          hasHero ? 'hasHero' : '' || this.props.hideSearch ? 'hideSearch' : ''
        }
      >
        <Header />
        {this.props.children}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  hasHero: state.headerReducer.hasHero
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateHeader: hasHero => {
    dispatch(updateHeader(hasHero))
  },
  updateMenu: updatedMenu => {
    dispatch(updateMenu(updatedMenu))
  },
  updateThemeOptions: options => {
    dispatch(updateThemeOptions(options))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
