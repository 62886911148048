import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import Link from 'next/link'
import { authorize } from './auth0'

class signinComponent extends React.Component {
  state = {
    username: '',
    password: ''
  }

  constructor(args){
    super(args);
    this.signin = this.signin.bind(this);
  }

  signin = function(e)  {
    e.preventDefault()
    authorize(this.state.username, this.state.password)
      .then(res => {
      })
      .catch(er => {
        this.setState({error:true})
      })
  }

  updateUsername = e => {
    this.setState({ username: e.target.value })
  }

  updatePassword = e => {
    this.setState({ password: e.target.value })
  }

  render() {
    return (
      <div className="auth-form-wrapper">
        <div className="auth-top">
          <h1>Välkommen till Fredsarkivet</h1>
          <p>Logga in genom att fylla i informationen nedan</p>
        </div>

        <div className="auth-form">
          <Form onSubmit={this.signin}>
            <FormGroup>
              <Label for="Email">Email</Label>
              <Input
                type="email"
                name="email"
                id="Email"
                value={this.state.username}
                onChange={e => {
                  this.updateUsername(e)
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="Password">Lösenord</Label>
              <Input
                type="password"
                name="password"
                id="Password"
                value={this.state.password}
                onChange={e => {
                  this.updatePassword(e)
                }}
              />
            </FormGroup>
            <Button color="primary">
              Logga in
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
            {
              this.state.error ? (<Label style={{color:"red"}} for="Password">Dina inloggningsuppgifter stämmer inte. Försök igen</Label>) : null
            }
            

          </Form>
          <div className="auth-extras">
            <FormGroup check className="remember-me">
              {/* <Label check>
                <Input type="checkbox" /> Kom ihåg mig
              </Label> */}
            </FormGroup>
            <Link href="/forgot-password">
              <a>Glömt lösenord?</a>
            </Link>
          </div>
          <p>
          Genom att fylla i kontaktformuläret ovan godkänner du att AMF sparar dina personuppgifter.
          </p>
        </div>
        <div className="auth-bottom">
          Har du inget konto?{' '}
          <Link href="/request-account">
            <a>
              Ansök om ett här <FontAwesomeIcon icon={faArrowRight} />
            </a>
          </Link>
        </div>
      </div>
    )
  }
}

export default signinComponent
