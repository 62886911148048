import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'

import { Collapse } from 'reactstrap'

export class FaqItem extends Component {
  state = {
    collapse: false
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse })
  }

  render() {
    return (
      <div className={`faq-item ${this.state.collapse ? 'open' : ''}`}>
        <div className="faq-item__head" onClick={this.toggle}>
          <h3>{this.props.question}</h3>
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
        <Collapse className="faq-item__body" isOpen={this.state.collapse}>
          <div className="body-inner">{this.props.answer}</div>
        </Collapse>
      </div>
    )
  }
}

export default FaqItem
