export const updateMenu = menu => ({
  type: 'UPDATE_MENU',
  payload: menu
})

export const updateFooterOne = footer => ({
  type: 'UPDATE_FOOTER_1',
  payload: footer
})

export const updateFooterTwo = footer => ({
  type: 'UPDATE_FOOTER_2',
  payload: footer
})

export const updateFooterThree = footer => ({
  type: 'UPDATE_FOOTER_3',
  payload: footer
})

export const updateMenus = footer => ({
  type: 'UPDATE_MENUS',
  payload: footer
})
