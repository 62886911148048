import Cookie from 'js-cookie'

const getAuth0 = (options) => {
  const config = require('./config.js')
  const auth0 = require('auth0-js');

  return new auth0.WebAuth({
    clientID: config.CLIENT_ID,
    domain: config.DOMAIN,
  });
}




  
  const getBaseUrl = () => `${window.location.protocol}//${window.location.host}`
  
  const getOptions = (container) => {
    return {
      responseType: 'token id_token',
      redirectUri: `${getBaseUrl()}/signedin`,
      scope: 'openid profile email'
    }
  }

  export let getProfile = () => {
    return new Promise((resolve, reject) => {
      let accessToken = Cookie.get("accessToken")
      if(accessToken){
        getAuth0().client.userInfo(accessToken, (err, profile) => {
          if (profile) {
            resolve(profile);
          } else{
            reject(err);
          }
         
        });
      } else{
        reject({message:"no cookie token"})
      }
      
    })
    
  }
    
  export var authorize = (username, password) => {
    return new Promise((resolve, reject)=> {
      getAuth0().login({
        realm: 'Username-Password-Authentication',
        email: username,
        password: password,
        responseType: 'token id_token',
        redirectUri:"https://fba.amfarkiv.se/signedin"
        
      }, function(err,success){
        if(err){
          reject(err)
          return 
        } 
        resolve(success)
      })
    })
  }

  export const requestPassword = function(email){
    

    return new Promise((resolve, reject) => {
      getAuth0().changePassword({
        connection: 'Username-Password-Authentication',
        email: email
      },function(err,a){
        if(err){
          reject(err)
        } else{
          resolve(a)          
        }

      })
    })
  }
  export const logout = () => getAuth0().logout({ returnTo: getBaseUrl()})
  export const parseHash = (callback) => getAuth0().parseHash(callback)