import React from 'react'
import PropTypes from 'prop-types'
import SigninComponent from './../authentication/signinComponent'
import defaultPage from './defaultPage'
import AuthLayout from './../layouts/AuthLayout'
const securePageHoc = Page => class SecurePage extends React.Component {
  static async getInitialProps (ctx) {
    return Page.getInitialProps ? await Page.getInitialProps(ctx) : null
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
  }
  render () {
    if (!this.props.isAuthenticated) {
      return ( <AuthLayout>
        <SigninComponent />
      </AuthLayout>)
    }
    return <Page {...this.props} />
  }
}

export default Page => defaultPage(securePageHoc(Page))