import React, { Component } from 'react'
import { Container, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { push, replaceAll, slugify } from './../src/navigation'
import { get } from './../src/fetchController'
import { connect } from 'react-redux'
import Link from 'next/link'
import {TOKEN} from '../authentication/config'

class Slide extends Component {
  state = {
    index: this.props.index,
    isActive: this.props.isActive,
    description: {},
    descList: []
  }

  getMetaData = (list, descId) => {
    let obj = list ? list.filter(data => data.descriptionId === descId) : null
    return obj && obj[0] ? obj[0].metaInhalt : null
  }

  navigate = () => {
    let metadataList = this.props.metadata[this.props.acfSlide.slide_px_id]
    let title = this.getMetaData(
      metadataList,
      '4028808769b923650169c46aeb320005'
    )
    push(
      '/item',
      '/post/' + this.props.acfSlide.slide_px_id + '/' + slugify(title)
    )
  }

  doDescription = description => {
    if (this.props.acfSlide.slide_text) {
      return <p>{this.props.acfSlide.slide_text}</p>
    } else {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: description.descriptionText
          }}
        />
      )
    }
  }

  doImage = description => {
    if (this.props.acfSlide.slide_image) {
      return this.props.acfSlide.slide_image
    } else {
      return description.image
    }
  }

  displayCountry = description => {
    if (description.country !== 'null') {
      return (
        <div className="item-information__object" style={{display: this.props.acfSlide.show_details ? "none" : "block"}}>
          <FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
          <span>{description.country}</span>
        </div>
      )
    }
  }

  render() {
    let id = this.props.acfSlide.slide_px_id
    let metadataList = this.props.metadata[id]
    let title = this.getMetaData(
      metadataList,
      '4028808769b923650169c46aeb320005'
    )
    let duration = this.getMetaData(
      metadataList,
      '402880485ce54644015d0deca8500021'
    )
    let year = this.getMetaData(
      metadataList,
      '402880485ce54644015d0d69616f0010'
    )
    let country = this.getMetaData(
      metadataList,
      '402880485ce54644015d0d66d5d1000b'
    )
    let descriptionText = this.getMetaData(
      metadataList,
      '402880485ce54644015d0e1b1251002b'
    )
    descriptionText = descriptionText
      ? descriptionText.replace(/<\/?[^>]+(>|$)/g, '')
      : null
    descriptionText = descriptionText
      ? descriptionText.substring(0, 150) + '...'
      : null
    let image = `https://stream.amfarkiv.se/proxsys-json/stream/content/thumbnail?auth=${TOKEN}&contentId=${id}&width=320&height=200`

    let description = {
      title,
      duration,
      year,
      country,
      descriptionText,
      image
    }

    let isActive = this.props.isActive
    let video
    if (this.props.acfSlide.slide_video) {
      video = (
        <video
          playsInline
          autoPlay
          muted
          loop
          poster={this.props.acfSlide.slide_image ? this.props.acfSlide.slide_image : ""}
          className="hero__video"
        >
          <source src={this.props.acfSlide.slide_video} type="video/mp4" />
        </video>
      )
    }

    return (
      <div
        className={`slide ${isActive ? 'active' : ''}`}
        style={{ backgroundImage: `url(${this.doImage(description)})` }}
      >
        {video}
        <Container>
          <div className="hero-content">
            <h2>
              {this.props.acfSlide.slide_title
                ? this.props.acfSlide.slide_title
                : description.title}
            </h2>
            <div className="item-information">
              <div className="item-information__object" style={{display: this.props.acfSlide.show_details ? "none" : "block"}}>
                <FontAwesomeIcon icon={['fal', 'calendar']} />
                <span>{description.year}</span>
              </div>
              <div className="item-information__object" style={{display: this.props.acfSlide.show_details ? "none" : "block"}}>
                <i className="fal fa-clock" />
                <FontAwesomeIcon icon={['fal', 'clock']} />
                <span>{description.duration}</span>
              </div>
              {this.displayCountry(description)}
            </div>
            {this.doDescription(description)}
            <Link
              href={
                '/post/' +
                this.props.acfSlide.slide_px_id +
                '/' +
                slugify(
                  this.props.acfSlide.slide_title
                    ? this.props.acfSlide.slide_title
                    : description.title
                    ? description.title
                    : ''
                )
              }
            >
              <Button color="link">
                Visa mer
                <FontAwesomeIcon icon={['fal', 'arrow-right']} />
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metadata: state.metaDataReducer
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Slide)
